import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import styles from './Location.module.scss'

const Location = ({ name, image, children }) => (
  <div className={styles.location}>
    <div className={styles.image}>
      <Img fluid={image} alt={name} />
    </div>

    <div className={styles.copy}>
      <h3 className="t-heading-xs">{name}</h3>

      <p className={styles.address}>{children}</p>
    </div>
  </div>
)

Location.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  image: PropTypes.object.isRequired,
}

export default Location
