import React from 'react'

import social from '@assets/social'
import styles from './Connect.module.scss'

const Connect = () => (
  <section className="section t-center">
    <div className="t-wrapper-m">
      <p>
        <a
          href="mailto:info@mosscc.org"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.email}
        >
          info@mosscc.org
        </a>
      </p>

      <div className={styles.social}>
        {social.map((entry) => (
          <a
            className={styles.social__item}
            href={entry.url}
            key={entry.url}
            rel="noopener noreferrer"
            title={entry.title}
            target="_blank"
          >
            {<entry.icon />}
          </a>
        ))}
      </div>
    </div>
  </section>
)

export default Connect
