import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Intro from '@components/ui/Intro'
import Location from './Location'
import styles from './Locations.module.scss'

const Locations = () => {
  const data = useStaticQuery(graphql`
    query locationsQuery {
      locationNetherlands: file(
        relativePath: { eq: "images/locationNetherlands.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256, maxHeight: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      locationMyanmar: file(
        relativePath: { eq: "images/locationMyanmar.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256, maxHeight: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      locationThailand: file(
        relativePath: { eq: "images/locationThailand.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256, maxHeight: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="section wrapper-l">
      <Intro sectionTitle="Locations" wrapper="wrapper-m">
        <p>
          These are the places where MOSS is active. Feel free to send us things
          by post. We like cake.
        </p>
      </Intro>

      <div className={styles.locationsWrapper}>
        <Location
          name="Netherlands (HQ)"
          image={data.locationNetherlands.childImageSharp.fluid}
        >
          Larikslaan 13
          <br />
          5671 BN Nuenen
          <br />
          The Netherlands
        </Location>

        <Location
          name="Myanmar"
          image={data.locationMyanmar.childImageSharp.fluid}
        >
          No. 33B, Room No. A3
          <br />
          Yaw Min Gyi Street 33B
          <br />
          Dagon Township 11191 Yangon
          <br />
          Myanmar
        </Location>

        <Location
          name="Thailand"
          image={data.locationThailand.childImageSharp.fluid}
        >
          191/79 Kiarti Thanee
          <br />
          Sukhumvit Soi 31
          <br />
          Bangkok 10110
          <br />
          Thailand
        </Location>
      </div>
    </section>
  )
}

export default Locations
