import React from 'react'

import Intro from '@components/ui/Intro'

const Hero = () => (
  <header className="hero t-center">
    <Intro pageTitle="Contact" wrapper="wrapper-s">
      <p className="t-italic">It’s nice to meet you!</p>
      <p>
        We’d like to get to know you better. Face-to-face is best but we accept
        virtual friendships. After all, a stranger is just a friend you haven’t
        met yet. There are a few ways that you can stay connected and join our
        community.
      </p>
    </Intro>
  </header>
)

export default Hero
