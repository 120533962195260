import React from 'react'

import Connect from '@components/pages/contact/Connect'
import Hero from '@components/pages/contact/Hero'
import Locations from '@components/pages/contact/Locations'
import Root from '@layouts/Root'
import Seo from '@components/utils/Seo'

const IndexPage = () => (
  <Root>
    <Seo title="Contact" />

    <Hero />
    <Connect />
    <Locations />
  </Root>
)

export default IndexPage
